<template>
  <Spinner v-if="isLoadingOrderList" />

  <section v-else>
    <h2 v-if="!orderList.length">
      Заказов нет
    </h2>

    <div v-else>
      <h2 class="mb-30">Заказы (позиций: {{ countOrderListPositions }})</h2>

      <div class="d-flex flex-col gap-s">
        <OrderCard
          v-for="order in orderList"
          :key="order.id"
          :order="order"
          @click.native="onOrderCard(order.id)"
        >
          <template v-if="order.from_outer_reclamation" v-slot:reclamation>
            <InfoText
              label="Статус рекламации"
              :text="getReclamationStatusName(order.from_outer_reclamation.status)"
            />

            <InfoText label="Решение по рекламации">
              <ReclamationExternalResolution
                :resolution-id="order.from_outer_reclamation.decision"
              />
            </InfoText>
          </template>
        </OrderCard>
      </div>

      <AppPagination
        v-if="pagination.pageCount > 1"
        :current-page="pagination.page"
        :page-count="pagination.pageCount"
        @setPage="setCurrentRouteQueryPage"
      />
    </div>
  </section>
</template>

<script>
import AppPagination from '@/components/ui/AppPagination'
import OrderCard from '@/components/production/order/OrderCard'

import { mixinRegisterListPage } from '@/mixins/views/Production/Order/mixinRegisterListPage'
import { mapActions, mapMutations, mapState } from 'vuex'
import { goToPage } from '@/utils/router'
import {
  reclamationExternalModel,
  ReclamationExternalResolution
} from '@/entities/reclamation-external'
import InfoText from '@/components/simple/text/InfoText.vue'

export default {
  components: {
    ReclamationExternalResolution,
    InfoText,
    AppPagination,
    OrderCard
  },

  mixins: [mixinRegisterListPage],

  computed: {
    ...mapState('order', [
      'countOrderListPositions',
      'orderList',
      'isLoadingOrderList',
      'pagination'
    ]),

    getReclamationStatusName: () => (id) => reclamationExternalModel.getStatusNameBy(id)
  },

  methods: {
    ...mapMutations('order', [
      'CLEAR_STATE'
    ]),
    ...mapActions('order', [
      'fetchOrderListData',
      'loadPage'
    ]),
    onOrderCard (id) {
      goToPage('OrderDetails', { id })
    }
  }
}
</script>
